import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SlickComponent() {
  var settings = {
    arrows: false,
    nav: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    cssEase: 'linear',
    autoplaySpeed: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    marginRight: 15,
    marginLeft: 15
  };
  var settingsNewSlide = {
    arrows: false,
    nav: true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    cssEase: 'linear',
    autoplaySpeed: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    rtl: true,
    // responsive: [
    //   480{

    //   }
    // ]
  };

  return (
    <>
      <h3 className="text-center py-5 sectTitle">Our Clients</h3>
      <div style={{maxWidth: '80%', margin: 'auto'}} className="container pt-3">
        <Slider {...settings}>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_1.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_2.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_3.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_4.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_5.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_6.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_7.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_8.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_9.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_10.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_11.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_12.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_14.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_15.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
        </Slider>
      </div>
      <div style={{maxWidth: '80%', margin: 'auto'}} className="container-opp-direct">
        <Slider {...settingsNewSlide}>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_16.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_17.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_18.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_19.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_20.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_21.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_22.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_23.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_24.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_25.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_26.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_27.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_28.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_29.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
          <div className="card1">
            <img src={require('./img/Client_logos/logo_30.jpg')} alt="right careers clients" className="w-100"></img>
          </div>
        </Slider>
      </div>
    </>
  );
}