import { Button, Modal } from "react-bootstrap"
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'

export const Hemant = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body >
                <section className="pb-4">
                    <div className="container popup px-3">
                        <div className="row">
                            <div className="col-md-4 imgSect">
                                <img className="" src={require('./img/Leaders/hemant.webp')} alt="" />
                                <h4 className="title mt-3 mb-0">Hemant Pawar</h4> 
                                <h4 className="mb-1">Sr. Vice President</h4>
                                <a target="_blank" href="https://www.linkedin.com/in/hemantrulzz/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><Linkedin className="socialmediaIcon" /></a>
                            </div>
                            <div className="col-md-8 px-4">
                                <p style={{color: '#000'}}>
                                Hemant Pawar is a passionate IT professional with a talent for managing enterprise technology projects. As a Principal Solutions Consultant, he specializes in digital transformation, delivering cutting-edge solutions across platforms like D365, Power Platform, SharePoint, and Salesforce. With a focus on building strong relationships with C-suite executives, Hemant drives sustainable business growth through strategic planning and seamless project execution. His expertise in cloud solutions and resource management ensures optimized, scalable results for clients looking to elevate their business performance in today’s fast-paced digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}