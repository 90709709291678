import { Button, Modal } from "react-bootstrap"
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'

export const Nizel = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <section>
                    <div className="container popup px-3">
                        <div className="row">
                            <div className="col-md-4 imgSect">
                                <img className="" src={require('./img/Leaders/nizel.webp')} alt="" />
                                <h4 className="title mt-3 mb-0">Nizel Jacinto</h4> 
                                <h4 className="mb-1">Founder and CEO</h4>
                                <a target="_blank" href="https://www.linkedin.com/in/nizel-jacinto-at-right-careers-301444182/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><Linkedin className="socialmediaIcon" /></a>
                            </div>
                            <div className="col-md-8 px-4">
                                <p style={{color: '#000'}}>
                                    Nizel Jacinto is a pivotal force at Right Careers India and Azucane IT Solutions LLC in Dubai, where he leads business operations with a strategic vision and a commitment to innovation. With a wealth of experience in technology and talent management, Nizel has been instrumental in driving the company’s growth, starting in India and expanding its global footprint with cutting-edge digital solutions and services.<br /><br />
                                    Under Nizel’s leadership, Azucane IT Solutions specializes in IT consulting, implementation, project execution, and managed services, forging strong partnerships with industry giants like Microsoft, SAP, Salesforce, Oracle, CrowdStrike, and Anamoli. His efforts have established Right Careers as a trusted partner in the industry, consistently achieving recognition as a Gold Partner with Microsoft, a Platinum Partner with Oracle, an SAP Network Partner, and a Salesforce Summit Partner for ERP and CRM solutions, while enhancing cybersecurity through collaboration with Anamoli.<br /><br />
                                    At Right Careers India, Nizel focuses on Human Capital Management (HCM), seamlessly integrating technology and talent to optimize workforce performance. His innovative approach streamlines HR processes, enhances employee engagement, and empowers organizations to thrive in today’s fast-paced business landscape.<br /><br />
                                    When he’s not leading the charge at Right Careers, Nizel enjoys exploring the latest advancements in technology and talent management, constantly seeking new ways to inspire and uplift his teams. <br /><br />

                                    His vision is clear: to create a workplace where both, individuals and businesses can reach their full potential.

                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}