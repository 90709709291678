import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AboutOverview } from './About-overview';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Demo } from './Demo';
import { JoinUs } from './JoinUs.js';
import { Connect } from './Connect.jsx';
import { Services } from './Services.jsx';

 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-overview" element={<AboutOverview />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path='/demo' element={<Demo />} />
        <Route path='/connect-with-us' element={<Connect />} />
        <Route path='/connect-with-us2' element={<Connect />} />
        <Route path='/services' element={<Services />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>       
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
