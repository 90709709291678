import { useEffect, useRef } from 'react';
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import Counter from './Counter';
import Servicesect from './ServiceSect';
import SlickComponent from './slickComponent';
import { Testimonial } from './Testimonial';
import { Header } from './Header';
import { Footer } from './Footer';
import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Header />
      {/* banner section */}
      <div className="bg-img">
        <div></div>
        <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">Empowering Talent, <br /><span style={{ fontWeight: 400 }}>Transforming Futures,</span> Together</h1>
        <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
      </div>
      {/* about overview section on home page */}
      <section ref={ref} style={{ backgroundColor: '#ef8f22' }} className='p-5 w80 sect-overview'>
        <p data-aos="fade-up" data-aos-easing="ease-in-sine" className='container pb-2 over-text'>
          Right Careers is a global IT and Non-IT Talent & Skills Management leader, connecting top talent with industry-leading organizations across the US, UK, UAE, India, and New Zealand.
        </p>
        <p data-aos="fade-up" data-aos-easing="ease-in-sine" className='container pb-2 over-text'>We specialize in providing immediate access to highly skilled professionals in in-demand tech domains such as Microsoft, SAP, Salesforce, Oracle, and Cybersecurity.</p>
        <button style={{ backgroundColor: '#bcc4c9' }} className='px-4 py-2 d-flex sbmt-btn border-0'>Read More</button><br />
      </section>
      <Counter />
      <Servicesect />
      <section data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-offset="300" className='py-5'>
        <SlickComponent />
      </section>
      <Testimonial />
      <Footer />
    </>
  );
};
export default App;