import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Testimonial = () => {
    const options = {
        items: 2,
        slideBy: 1,
        dotsEach: true,
        autoplay: true,
        autoplayTimeout: 3000,
        loop: true
    };

    return (
        <>
            <section data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-offset="300" className="testimonialSect py-5">
                <h3 className="text-center sectTitle">Testimonial</h3>
                <div className="container testCards pt-5">
                    <div className="row justify-content-center">
                        {/* <Slider {...testimonial} style={{ maxWidth: '85%' }} className="testCards">
                        <div className="card card1 col-md-5 mx-2">
                            <h5>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                        <div className="card card2 col-md-5 mx-2">
                            <h4>Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</h4>
                            <img src={require('./img/EY.png')} alt="" className="cardImg"></img>
                        </div>
                    </Slider> */}
                        <OwlCarousel className="owl-theme" {...options}>
                            <div data-aos="flip-left" data-aos-duration="700" className="card card1 mx-2 px-5 py-4">
                                <p className="words">Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</p>
                                <div className="clientInfo">
                                    <h4>Jennifer Issac</h4>
                                    <p>- Director | Crossasyst Infotech Private Limited</p>
                                </div>
                                <img src={require('./img/CrossAsyst.webp')} alt="" className="cardImg"></img>
                            </div>
                            <div data-aos="flip-left" data-aos-duration="700" className="card card2 mx-2 px-5 py-4">
                                <p className="words">Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</p>
                                <div className="clientInfo">
                                    <h4>Sagar Khanna</h4>
                                    <p>- Director | Crossasyst Infotech Private Limited</p>
                                </div>
                                <img src={require('./img/BillDesk.webp')} alt="" className="cardImg"></img>
                            </div>
                            <div data-aos="flip-left" data-aos-duration="700" className="card card1 mx-2 px-5 py-4">
                                <p className="words">Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</p>
                                <div className="clientInfo">
                                    <h4>Jennifer Issac</h4>
                                    <p>- Director | Crossasyst Infotech Private Limited</p>
                                </div>
                                <img src={require('./img/CrossAsyst.webp')} alt="" className="cardImg"></img>
                            </div>
                            <div data-aos="flip-left" data-aos-duration="700" className="card card2 mx-2 px-5 py-4">
                                <p className="words">Such an amazing recruitment company! The team really care and take the time to find out exactly what you want and take away all the stress of recruitment.</p>
                                <div className="clientInfo">
                                    <h4>Jennifer Issac</h4>
                                    <p>- Director | Crossasyst Infotech Private Limited</p>
                                </div>
                                <img src={require('./img/BillDesk.webp')} alt="" className="cardImg"></img>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    );
}