import { MdLocationPin } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { SocialIcon } from 'react-social-icons'
import { ReactComponent as Facebook } from './img/svg/facebook_logo_icon.svg'
import { ReactComponent as Instagram } from './img/svg/instagram_logo_icon.svg'
import { ReactComponent as Youtube } from './img/svg/youtube_videos_icon.svg'
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'
import Pdf1 from './pdfs/Corporate_Stewardship_Policy.pdf';
import Pdf2 from './pdfs/Data Privacy Framework.pdf';
import Pdf3 from './pdfs/Environment Safety Policy.pdf';
import Pdf4 from './pdfs/Gifts, Hospitality and Ethical Conduct.pdf';
import Pdf5 from './pdfs/Operational Resilience Policy.pdf';
import Pdf6 from './pdfs/Workplace Dignity and Respect Policy.pdf';
import { Link } from "react-router-dom";

export const Footer = () => {
    return <>
        <section className="footer">
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-5 informative">
                        <img alt='Client Logo' src={require("./img/right_career_logo_footer.webp")} />
                        <div className="location mt-3">
                            <span className="pe-2"><MdLocationPin className="icons" style={{ fontSize: 30 }} /></span>
                            <span className="">MBC Infotech Park, Office No. 112,<br /> 1st Floor, D-Wing. Ghodbunder Road, Kasarvadavli,<br /> Thane 400615, Maharashtra, India.</span>
                        </div>
                        <div className="location mt-3">
                            <span className="pe-2"><BiSolidPhoneCall className="icons" style={{ fontSize: 30 }} /></span>
                            <span className=""><a href="tel:+91 9307416089">+91 9307416089</a></span>
                        </div>
                        <div className="location mt-3">
                            <span className="pe-2"><IoIosMail className="icons" style={{ fontSize: 30 }} /></span>
                            <span className=""><a href="mailto:hr@rightcareers.co.in">hr@rightcareers.co.in</a></span>
                        </div>
                    </div>

                    <div className="col-md-3 mt-5 pt-2">
                        <p className="mb-0 ql"><b>Quick Links</b></p>
                        <ul className="ps-0 links" style={{ listStyle: "none" }}>
                            <li><a href='/'>Home</a></li>
                            <li><a href='/about-overview'>Overview</a></li>
                            <li><a href='/join-us'>Join Us</a></li>
                            <li><a href='/connect-with-us'>Connect with Us</a></li>
                            <li><a href='/services'>Services</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 mt-5 pt-2">
                        <p className="mb-0"><b>Policies For Right Careers</b></p>
                        <ul className="ps-0 links" style={{ listStyle: "none" }}>
                            <li><a href={Pdf1 + "#toolbar=0"} target="_blank">Corporate Stewardship Policy</a></li>
                            <li><a href={Pdf2 + "#toolbar=0"} target="_blank">Data Privacy Framework</a></li>
                            <li><a href={Pdf3 + "#toolbar=0"} target="_blank">Environment Safety policy</a></li>
                            <li><a href={Pdf4 + "#toolbar=0"} target="_blank">Gifts, Hospitality & Ethical conduct</a></li>
                            <li><a href={Pdf5 + "#toolbar=0"} target="_blank">Operational Resilience Policy</a></li>
                            <li><a href={Pdf6 + "#toolbar=0"} target="_blank">Workplace Dignity and Respect Policy</a></li>
                        </ul>
                        <div className="socialMedia mt-3 mx-auto">
                            <p className="mb-1"><b>Follow Us</b></p>
                            <div className="socialIcons">
                                <a target="_blank" href="https://www.linkedin.com/company/right-careers-in/"><Linkedin className="socialmediaIcon" /></a>
                                <a target="_blank" href="https://www.facebook.com/rightcareersoffical?mibextid=ZbWKwL"><Facebook className="socialmediaIcon" /></a>
                                <a target="_blank" href="https://www.instagram.com/rightcareers?igsh=MTNzeGttYTFzMWVtNw"><Instagram className="socialmediaIcon" /></a>
                                <a target="_blank" href="https://www.youtube.com/@Right.Careers"><Youtube className="socialmediaIcon" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="" style={{ backgroundColor: '#fff' }}>
                <div className="copyright my-2 container">
                    <p className="mb-0">© Right Careers 2024. All Rights Reserved.</p>
                    <p className="mb-0 internlinks"><a href="#">Terms & Conditions</a> | <a href="#">Privacy Policy</a> | <a href="#">Cookies Policy</a></p>
                </div>
            </div>
        </section>

    </>
}