import { Button, Modal } from "react-bootstrap"
import { ReactComponent as Linkedin } from './img/svg/linkedin_logo_icon.svg'

export const Sonali = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <section>
                    <div className="container popup px-3">
                        <div className="row">
                            <div className="col-md-4 imgSect">
                                <img className="" src={require('./img/Leaders/sonali.webp')} alt="" />
                                <h4 className="title mt-3 mb-0">Sonali Kamath</h4> 
                                <h4 className="mb-1">Chief Human Resources Officer (CHRO)</h4>
                                <a target="_blank" href="https://www.linkedin.com/in/sonali-kamath-0104061b2/"><Linkedin className="socialmediaIcon" /></a>
                            </div>
                            <div className="col-md-8 px-4">
                                <p style={{color: '#000'}}>
                                    Sonali Kamath is a seasoned HR professional with over 18 years of experience in strategic talent management and organizational development. Known for her expertise in competency mapping and employee performance optimization, Sonali has a passion for aligning HR goals with business objectives to drive success. Her leadership in Human Capital Management (HCM) has empowered organizations across diverse industries to build high-performance cultures, fostering employee growth and engagement. Sonali's focus on talent development and her commitment to innovation continue to elevate the HR function in today's dynamic business environment.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}