import Container from 'react-bootstrap/Container';
import './menu.css'
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

export const Header = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="newHeader">
                    <Link to="/"><img alt='Client Logo' src='right_career_home1.webp' /></Link>
                    <div className="menu">
                        <div className="menuItem dropDown">
                            <a className="">About Us<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <Link to="/about-overview">Overview</Link>
                                <Link to="/join-us">Join Us</Link>
                                <Link to="/connect-with-us">Connect with Us</Link>
                            </div>
                        </div>
                        <div className="menuItem dropDown">
                            <a className="">Industries<MdKeyboardArrowDown className='dropdownIcon' /></a>
                            <div className="dropwdownBox">
                                <Link to="#">Service1</Link>
                                <Link to="#">Service2</Link>
                                <Link to="#">Service3</Link>
                            </div>
                        </div>
                        <div ><Link className="menuItem" style={{textDecoration: 'none'}} to="/services">Service</Link></div>
                        <div className="menuItem"><a className="">Blog</a></div>
                    </div>
                </div>
            </div>
        </>
    )
}