import { useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { Header } from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Footer } from "./Footer";
import './services.css'

export const Services = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const ref = useRef()
    return (
        <>
            <Header />
            {/* banner section */}
            <div className="bg-img services_bg">
                <div className="bdcrumb"><Link to="/">Home</Link> | Services</div>
                <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-sine" className="headline-text text-white">The Right Choice for <br /><span className="subhead" style={{ fontWeight: 400 }}>Your Hiring Needs </span></h1>
                <div className="text-center custom-icon"><IoIosArrowDown onClick={handleClick} className='custom-typography' /></div>
            </div>
            {/* about overview section on home page */}
            <section ref={ref} style={{ backgroundColor: '#ef8f22' }} className='p-5 w80 sect-overview services'>
                <p data-aos="fade-up" data-aos-easing="ease-in-sine" className='container over-text'>
                    At Right Careers, we optimize your hiring process for maximum efficiency and productivity, using real-time data analytics to make informed decisions. With our Consultative Talent Assessment Process, we deliver top talent with zero lead time, ensuring your business keeps moving forward.
                </p>
            </section>
            <section className="clients my-5">
                <div className="headings">
                    <h2 style={{ fontWeight: 800 }} className="text-center mytext">The Trusted Choice of Fortune 500</h2>
                    <h2 style={{ fontWeight: 800 }} className="text-center mytext2">Companies and Businesses Globally</h2>
                    <div className="container text-center">
                        <div data-aos="flip-left" data-aos-duration="2000" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_1.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_2.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_3.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_4.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_5.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_6.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_7.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_8.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_9.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_10.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_11.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_12.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_13.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_14.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_15.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_16.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_17.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_18.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_19.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_20.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_21.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_22.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_23.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_24.jpg')} alt="Right careers clients" />
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000" className="imgrows imgrow1">
                            <img src={require('./img/Client_logos/logo_25.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_26.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_27.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_28.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_29.jpg')} alt="Right careers clients" />
                            <img src={require('./img/Client_logos/logo_30.jpg')} alt="Right careers clients" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 srvcGrntee text-center">
                <div className="container">
                    <h2 data-aos="fade-down" className="" style={{ color: '#fff', fontWeight: "600", fontSize: "35px" }}>Our proven 95% on-time delivery success rate makes us the backbone of hiring for Fortune 500 companies globally.</h2>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-left" data-aos-duration="1500" className="col-md-7 rghtSect">
                            <p className="mb-0">
                                We dive deep into your unique business needs, creating a customized hiring strategy to find the perfect fit for each role.<br /><br />
                                We enhance talent with tailored skill-based training programs led by certified industry experts, ensuring your team stays ahead of the curve.<br /><br />
                                We transform recruitment by broadening search parameters, enhancing your employer brand, and showcasing growth opportunities.
                                By connecting with the right talent through social media and educational partnerships, we invest in employee development and maintain a robust talent pipeline to experience flexibility and a positive candidate journey for building a brighter future together.
                            </p>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="1000" className="col-md-5 lftSect d-flex">
                            <p className="lftTitle align-self-center">Our innovative R2R model, powered by AI-HR tools, drives hypergrowth in ROI by up to 50%, with an unbeatable 2:2 Hiring Plan—two pre-screened candidates delivered within 48 hours.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="d7r">
                <div className="container">
                    <p className="rs7 button">Our 7 R's  that Makes Us Your Right Choice </p>
                    <div className="elipses">
                        <div className="elipse elipse1">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon1.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">1</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Recruit</h5>
                                    <p>the Recruiter</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse2">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon2.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">2</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Rated as</h5>
                                    <p>'Preferred Choice' by global 'Job Seekers & Job Creators'</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse3">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon3.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">3</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Redefined</h5>
                                    <p>Pricing breaking the industry standards</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse4">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon4.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">4</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Real</h5>
                                    <p>time Data Analysis</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse5">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon5.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">5</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Reduced</h5>
                                    <p>Time On Hiring</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse6">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon6.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">6</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">Right Tools </h5>
                                    <p>AI Backed Search Engine, 2.5 million + In house Talent database</p>
                                </div>
                            </div>
                        </div>
                        <div className="elipse elipse7">
                            <div data-aos="fade-down" data-aos-duration="2000" className="upperpart">
                                <hr className="line1" />
                                <img data-aos="flip-left" data-aos-duration="2000" src={require('./img/services_icons/icon7.png')} alt="" />
                            </div>
                            <div data-aos="fade-down" data-aos-duration="2000" className="bottompart">
                                <hr className="line1" />
                                <p className="rounder">7</p>
                                <div className="texts text-center">
                                    <h5 className="mb-0">ROI Driven</h5>
                                    <p>Result Oriented</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}