export const Employee = () => {
    return (
        <> 
            <div className="employee">
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Previous Employer:</label>
                    <input className="inpt" type="email" />
                </div>
                <div className="inptBlock mt-2 col-md-5">
                    <label htmlFor="Name">Company Website:</label>
                    <input className="inpt" type="email" />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Previous Designation:</label>
                    <input className="inpt" type="email" />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Years of Employment</label>
                    <input className="inpt" type="text" />
                </div>
                <div className="inptBlock col-md-5"></div>
                <div className="inptBlock textField d-block">
                    <label htmlFor="Name">Message:</label>
                    <textarea className="inpt" type="text" />
                </div>
                <div className="inptBlock col-md-5">
                    <label htmlFor="Name">Upload Resume</label>
                    <input className="fileUploadBlck" type="file" />
                </div>
                <div className="inptBlock col-md-5"></div>
            </div>
        </>
    )
}