export default function Servicesect() {
    return (
        <>
            <section className="service-sect">
                <video className='videoBg' autoPlay muted loop>
                    <source src={require('./video/team-building-conversation.mp4')} type="video/mp4" />
                </video>
                <div className="container videoContent py-5 px-3">
                    <div className="innerContent">
                        <h3 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" className="text-center py-3 sectTitle">Our Services</h3>
                        <div className="row pt-5" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div data-aos="fade-right" data-aos-duration="800" className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/recruit_without_bg.webp')} alt="Right careers services" />
                                <h3 className="text-center">Recruitment</h3>
                                <p>We specialize in identifying and attracting top talent tailored to meet your organization's specific needs, ensuring the perfect fit for your team.</p>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="1500" className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/full_time.webp')} alt="Right careers services" />
                                <h3>Full-Time Employment</h3>
                                <p>Our dedicated team connects you with candidates seeking long-term career opportunities, providing a seamless hiring experience.</p>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2200" className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/permanent.webp')} alt="Right careers services" />
                                <h3>Permanent Contract</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2200" className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/icons-inner-page/R2R.png')} alt="Right careers services" />
                                <h3>Recruit the Recruiter (R2R)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2200" className="col-md-4 text-center boxes px-5">
                                <img src={require('./img/icons-inner-page/C2C.png')} alt="Right careers services" />
                                <h3>Contract-to-Hire (C2H)</h3>
                                <p>We offer flexible permanent contract solutions, allowing businesses to engage talent while maintaining workforce stability.</p>
                            </div>
                        </div>
                        <div className="text-center cta-btn pt-4 pb-3">
                            <button className='br-none servicBtn'>Read More</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
