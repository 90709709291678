import Accordion from 'react-bootstrap/Accordion';

function BasicExample() {
  return (
    <Accordion data-aos="flip-up" data-aos-offset="300" data-aos-duration="2000" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Communication</Accordion.Header>
        <Accordion.Body>
        We foster open, transparent channels for sharing ideas and feedback.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Leadership</Accordion.Header>
        <Accordion.Body>
        Empowering management that actively engages and supports staff.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Environment</Accordion.Header>
        <Accordion.Body>
        A positive, collaborative workplace that values both physical and emotional well-being.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Engagement</Accordion.Header>
        <Accordion.Body>
        Encouraging commitment, enthusiasm, and active participation in work.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Inclusion</Accordion.Header>
        <Accordion.Body>
        We champion diversity and inclusion, embracing all perspectives.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Balance</Accordion.Header>
        <Accordion.Body>
        Prioritizing support for employees’ professional and personal lives.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Growth</Accordion.Header>
        <Accordion.Body>
        Providing opportunities for continuous learning and career advancement.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default BasicExample;